<template>
  <b-modal
    id="modal-import"
    :title="!importSuccess ? 'Thêm file' : 'Import thành công'"
    :size="!importSuccess ? 'md' : 'xl'"
    centered
    hide-footer
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <div v-if="!importSuccess" class="py-4">
          <label for="">Import file</label>
          <div class="wrap__file mb-1">
            <span class="file__name">
              {{ file ? fileName : 'file import.xlsx' }}
            </span>
            <b-icon-upload
              scale="1.5"
              class="cursor-pointer"
              @click="$refs.fileUpload.click()"
            ></b-icon-upload>
            <input
              v-show="false"
              type="file"
              name=""
              id="file-upload"
              ref="fileUpload"
              accept=".xlsx, .xls"
              @change="onUploadFile"
            />
          </div>
          <span v-if="showFileSizeError" class="text-danger"
            >Kích thước file import tối đa nhỏ hơn 1MB</span
          >
        </div>
        <div v-else>
          <b-row>
            <b-col cols="3" class="d-flex">
              <div class="wrap__icon">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/Active-account/user-group.svg" />
                </span>
              </div>
              <div>
                <h6>Tổng số bản ghi</h6>
                <p>{{ paging.total }}</p>
              </div>
            </b-col>
            <b-col cols="3" class="d-flex">
              <div class="wrap__icon">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/Active-account/check.svg" />
                </span>
              </div>
              <div>
                <h6>Tổng số bản ghi import thành công</h6>
                <p>{{ totalSuccess }}</p>
              </div>
            </b-col>
          </b-row>
          <template-table
            :column="column"
            :data="customers"
            :paging="paging"
            :tableAction="false"
            :selectAction="false"
            :searchAction="false"
          >
            <template v-slot:body="{ item }">
              <td>{{ item.number }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.phoneNumber }}</td>
              <td>{{ item.birthYear }}</td>
              <td class="text-center">
                {{ item.gender }}
              </td>
              <td class="text-center">{{ item.contactSource }}</td>
              <td>{{ item.diseaseType }}</td>
              <td>{{ item.course }}</td>
              <td class="text-center">{{ item.coach }}</td>
              <td>
                <div
                  class="status"
                  :class="getClassByStatus(item.transactionStatus)"
                >
                  {{ getStatus(item.transactionStatus) }}
                </div>
              </td>
              <td>
                <div v-for="(err, idx) in item.errorMessage" :key="idx">
                  - {{ err }}
                </div>
              </td>
            </template>
          </template-table>
        </div>
        <div
          v-if="!importSuccess"
          class="d-flex justify-content-end align-items-center mt-4"
        >
          <b-button class="btn mr-2" type="button" @click="onClickCancelButton">
            Hủy
          </b-button>
          <b-button
            class="btn btn-success ml-3"
            type="submit"
            :disabled="disableBtnImport"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Import
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import { EXCEL_FILE_TYPE } from '@/core/plugins/constants';
export default {
  name: 'ModalImport',
  data() {
    return {
      importSuccess: false,
      file: null,
      showFileSizeError: false,
      loading: false,
      customers: [],
      allCustomers: [],
      column: [
        {
          key: 'number',
          label: 'STT',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Họ tên',
          sortable: false,
        },
        {
          key: 'phoneNumber',
          label: 'Số điện thoại',
          sortable: false,
        },
        {
          key: 'birthYear',
          label: 'Ngày sinh',
          sortable: false,
        },
        {
          key: 'gender',
          label: 'Giới tính',
          sortable: false,
          class: 'text-center',
        },
        {
          key: 'contactSource',
          label: 'Nguồn tiếp nhận',
          sortable: false,
          class: 'text-center',
        },
        {
          key: 'diseaseType',
          label: 'Phân loại bệnh',
          sortable: false,
        },
        {
          key: 'course',
          label: 'Khóa',
          sortable: false,
          class: 'text-center',
        },
        {
          key: 'coach',
          label: 'Coach phụ trách',
          sortable: false,
          class: 'text-center',
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
        },
        {
          key: 'errorMessage',
          label: 'Chi tiết lỗi',
          sortable: false,
        },
      ],
      paging: {
        total: 0,
        page: 1,
        pageSize: 10,
      },
      totalSuccess: 0,
    };
  },
  computed: {
    fileName() {
      return this.file && this.file.name;
    },
    disableBtnImport() {
      return !this.file || this.showFileSizeError;
    },
  },
  watch: {
    'paging.page'(val) {
      this.customers = this.paginate(
        this.allCustomers,
        this.paging.pageSize,
        val,
      );
    },
    'paging.pageSize'(val) {
      this.customers = this.paginate(this.allCustomers, val, 1);
    },
  },
  methods: {
    resetModal() {
      this.importSuccess && this.$emit('import-success');
      this.file = null;
      this.showFileSizeError = false;
      this.importSuccess = false;
      this.$emit('reset');
    },
    onClickCancelButton() {
      this.$bvModal.hide('modal-import');
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;

        this.handleSubmit();
      });
    },
    async handleSubmit() {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append('importFile', this.file);
        const { meta, data, error } = await this.$api.post(
          'CustomerReceives/ImportCustomerReceives',
          formData,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.allCustomers = data.data.map((el) => ({
          ...el,
          errorMessage: this.getErrorMessages(el.errorMessage),
        }));
        this.paging.total = data.total;
        this.totalSuccess = data.totalSuccess;
        this.customers = this.paginate(
          this.allCustomers,
          this.paging.pageSize,
          this.paging.page,
        );
        this.importSuccess = true;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    getErrorMessages(text) {
      if (!text) return [];
      const errorMessages = text.split('\n');
      errorMessages.pop();
      return errorMessages;
    },
    onUploadFile(event) {
      const file = event.target.files[0];
      // validate file type
      if (!EXCEL_FILE_TYPE.includes(file.type)) {
        return (this.$refs.fileUpload.value = null);
      }

      if (file.size > 1024000) {
        return (this.showFileSizeError = true);
      }

      this.file = file;
      this.showFileSizeError = false;
    },
    getStatus(status) {
      if (status) return 'Thành công';
      return 'Lỗi';
    },
    getClassByStatus(status) {
      if (status) return 'active';
      return 'inactive';
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap__file {
  padding: 10px 16px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap__icon {
  background: #e4f5f5;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status {
  padding: 6px 12px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  width: 110px;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }
}
</style>

<style lang="scss" scoped>
.wrap__file {
  padding: 10px 16px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap__icon {
  background: #e4f5f5;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status {
  padding: 6px 12px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  width: 110px;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }
}
</style>

<style lang="scss" scoped>
.wrap__file {
  padding: 10px 16px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrap__icon {
  background: #e4f5f5;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status {
  padding: 6px 12px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  width: 110px;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }
}
</style>

<style lang="scss">
#modal-import {
  .modal-dialog.modal-xl {
    max-width: 1500px;
  }
}
</style>
